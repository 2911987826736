
import { defineComponent, PropType } from "vue";
import { SystemIconName } from "../SystemIcon/types";
import SystemIcon from '@/components/SystemIcon'

export default defineComponent({
  components: { SystemIcon },
  props: {
    icon: {
      type: String as PropType<SystemIconName>
    },
    iconSize: {
      type: Number,
      default: 13
    },
    noHover: {
      type: Boolean,
      default: false
    }
  }
})
